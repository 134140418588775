/* Colours, fonts and variables */
@import url("https://use.typekit.net/plr2wdk.css");
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400&display=swap');
:root {
    --bgWhite: #fff;
    --bgBlack: rgba(0,0,0,0.4);
    --transWhite: rgba(255,255,255,0.15);
    
    --txtWhite: #fff;
    --txtBlack: #000;
    --txtBlue: #012265;

    --yellow: #FDC743;
    --blue: #57AEE2;
    --darkBlue: #012265;
    --darkBlueFaded: #04308B;
    --lghtBlue: #F2FAFF;
    --grn: #42c50a;
    --red: #d42c19;

    --border: #707070;
}

/* Key core styles */
body {
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px;
  background: var(--bgWhite);
  color: var(--txtBlue);
  overflow-x: hidden;
}

h1, h2 {
    font-size: 60px;
    margin-bottom: 32px;
    font-family: "urbane-rounded", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--darkBlue);
    line-height: 1.2em;
    padding-bottom: 26px;
    margin-bottom: 22px;
    position: relative;
    display: inline-block;
}
h1:after, h2:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 448px;
    background: var(--blue);
    height: 6px;
    border-radius: 8px;
}
h2 {
    font-size: 44px;
    padding-bottom: 22px;
    margin-bottom: 60px;
}
h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 22px;
}

button.btn, a.btn {
  background: var(--darkBlue);
  border-radius: 42px;
  color: var(--txtWhite);
  border: none;
  padding: 18px 42px;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  box-sizing: border-box;
  text-align: center;
  transition: 0.3s;
  min-width: 170px;
  cursor: pointer;
}
button.btn.sml,
a.btn.sml {
    padding: 10px 25px;
    font-size: 14px;
    min-width: 0;
}
button.btn.centred,
a.btn.centred {
    text-align: center;
}
a.btn.primary:hover,
button.primary:hover,
a.btn.upgrade:hover {
    background-color: var(--darkBlueFaded);
}

button.btn.secondary {
    background: var(--bgWhite);
    color: var(--darkBlue);
    border: 2px solid var(--darkBlue);
    padding: 16px 40px;
}
button.btn.secondary:hover {
    background: var(--lghtBlue);
}

a { 
    color: var(--darkBlue); 
    text-decoration: none; 
    padding-bottom: 0; 
    border-bottom: 1px solid var(--txtBlack); 
    transition: 0.3s; 
}
a:hover { border-bottom-color: transparent; }

p { margin-bottom: 18px; }
p.fig {
  color: var(--txtGrey);
  font-size: 14px;
  margin-bottom: 28px;
}
p.sml {
  font-size: 13px;
}

hr.password-divider {
  border: 0;
  height: 1px;
  background: var(--border);
  margin: 32px 0;
}

.mbtm {
  margin-bottom: 42px !important;
}

/* Header / menu / menu */
header {
    background: var(--bgWhite);
    padding: 30px 0;
}
header.admin {
    background: var(--yellow);
}
header .logo { overflow: auto; }
header.admin .logo {
    background: var(--bgWhite);
    border-radius: 32px;
    padding: 16px 18px;
    box-sizing: border-box;
    width: 528px;
}
header .logo img {
    max-width: 320px;
    height: auto;
    float: left;
}
header .logo img.cb-logo {
    max-width: 145px;
    margin-top: 75px;
    margin-left: 6px;
}
header.admin .main-nav {
    float: right;
}
header .main-nav ul {
    list-style: none;
    margin: 52px 0;
    padding: 0;
    font-size: 22px;
    text-align: right;
}
header.admin .main-nav ul {
    float: right;
    margin: 66px 0;
}
header .main-nav ul li {
    display: inline-block;
    margin-left: 42px;
}
header .main-nav ul li a {
    border: none;
    font-weight: 500;
    letter-spacing: 1px;
    color: var(--darkBlue);
}
header .main-nav ul li a:hover {
    color: var(--blue)
}
header .main-nav button.menu-toggle { display: none; }

footer {
    background: var(--bgWhite);
    padding: 60px 0;
    text-align: center;
}
footer.admin {
    background: var(--lghtBlue);
}
footer p {
    margin: 0;
    font-size: 12px;
    font-weight: 300;
}
footer p.powered {
    font-size: 14px;
}
footer img {
    width: 200px;
    margin: 12px auto 32px auto;
}

/* Landing page styles */
.landing {
    width: 100vw;
    overflow-x: hidden;
}
.landing .head-banner {
    background: var(--yellow);
    padding: 105px 0 60px 0;
    color: var(--txtBlack);
    position: relative;
    overflow: hidden;
}
.landing .head-banner .bg-img {
    position: absolute;
    top: 0;
    right: -22px;
    width: auto;
    height: 100%;
    transition: 0.3s;
    opacity: 1;
}
.landing .head-banner .bg-img.load-it {
    opacity: 0;
    right: -250px;
}
.landing .head-banner a.btn {
    margin-top: 32px;
}

.landing .expectations {
    padding: 100px 0 130px 0;
    overflow: auto;
}
.landing .expectations .content {
    margin: 0 auto;
}
.landing .expectations .content .img-rght {
    width: calc(95% - 250px);
    margin: 0 25px;
}
.landing .expectations .third.blue {
    background: var(--lghtBlue);
    padding: 34px;
    box-sizing: border-box;
    border-radius: 34px;
    color: var(--darkBlue);
    text-align: center;
    min-height: 302px;
}
.landing .expectations .third.blue h5 {
    font-size: 24px;
}
.landing .expectations .third.blue img {
    max-width: 220px;
    width: 100%;
}
/* .landing .expectations .third.blue:first-child img {
    max-width: 160px;
    margin: 35px auto;
} */

.landing .how-it-works {
    background: var(--blue);
    padding: 42px 0 120px 0;
    clear: both;
    text-align: center;
    position: relative;
}
.landing .how-it-works .content {
    overflow-x: hidden;
}
.landing .how-it-works .right-girl {
    position: absolute;
    right: -42px;
    top: -348px;
    width: 472px;
    height: 472px;
}
.landing .how-it-works .right-girl:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100% !important;
    max-width: 448px;
    background: var(--blue);
    height: 6px;
    border-radius: 472px 0 0 0;
    z-index: -1
}
.landing .how-it-works .right-girl img {
    width: 100%;
}
.landing .how-it-works .h-wrap {
    text-align: left;
}
.landing .how-it-works h2:after {
    background: var(--bgWhite);
}
.landing .how-it-works .third {
    padding: 0 32px;
    box-sizing: border-box;
    color: var(--darkBlue);
}
.landing .how-it-works .third img {
    width: 145px;
    height: 145px;
}
.landing .how-it-works h5 {
    margin-top: 52px;
    margin-bottom: 22px;
    font-size: 36px;
    font-weight: 500;
}
.landing .how-it-works p {
    font-size: 22px;
    font-weight: 400;
}
.landing .how-it-works a.btn {
    margin-top: 48px;
}

/* Questionnaire / login page styles */
.questionnaire {
    margin-top: 80px;
    text-align: center;
    overflow: hidden;
    position: relative;
    min-height: 1280px; /* TODO: Make this dynamic  */
}
.questionnaire.short {
    min-height: 750px;
}
.questionnaire.big {
    min-height: 1400px;
}
.questionnaire h1 {
    margin-top: 0;
    font-size: 44px;
    padding-bottom: 18px;
    margin-bottom: 52px;
}

.questionnaire div.intro p {
    font-size: 22px;
    line-height: 36px;
}
.questionnaire:after {
    content: "";
    display: block;
    background: var(--yellow);
    width: 100%;
    height: 2500px;
    top: 400px;
    position: absolute;
    z-index: -2;
    transition: 0.8s;
}
.questionnaire.blue:after {
    background: var(--blue);
}
.questionnaire .q-side.lft {
    content: "";
    display: block;
    background: var(--blue);
    width: 650px;
    height: 700px;
    top: 140px;
    left: 0;
    border-radius: 0 650px 0 0;
    position: absolute;
    z-index: -1;
    transition: 0.8s;
    opacity: 0;
}
.questionnaire.blue .q-side.lft {
    opacity: 1;
}
.questionnaire .side-img.lft {
    position: absolute;
    top: 0;
    left: 0;
    width: 360px;
    height: 650px;
    background: var(--yellow);
    border-radius: 0 360px 0 0;
    z-index: -1;
    opacity: 1;
    transition: 0.8s;
}
.questionnaire.blue .side-img.lft {
    opacity: 0;
}
.questionnaire .side-img.lft img {
    width: 540px;
    height: auto;
    top: 110px;
    left: 12px;
    position: absolute;
    opacity: 1;
    transition: 0.8s;
}
.questionnaire .q-side.rght {
    content: "";
    display: block;
    background: var(--yellow);
    width: 650px;
    height: 700px;
    top: 140px;
    right: 0;
    border-radius: 650px 0 0 0;
    position: absolute;
    z-index: -1;
    transition: 0.8s;
    opacity: 1;
}
.questionnaire.blue .q-side.rght {
    opacity: 0;
}
.questionnaire .side-img.rght {
    content: "";
    display: block;
    background: var(--blue);
    width: 360px;
    height: 650px;
    top: 0;
    right: 0;
    border-radius: 360px 0 0 0;
    position: absolute;
    z-index: -1;
    transition: 0.8s;
    opacity: 0;
}
.questionnaire.blue .side-img.rght {
    opacity: 1;
}
.questionnaire .side-img.rght img {
    width: 540px;
    height: auto;
    top: 55px;
    right: -32px;
    position: absolute;
    opacity: 1;
}

.questionnaire .q-content {
    position: relative;
    width: 80%;
    max-width: 620px;
    display: block;
    min-height: 500px;
    margin: 0 auto;
}
.questionnaire .q-content.big {
    width: 90%;
    max-width: 860px;
}
.questionnaire .q-content h3 {
    font-size: 24px;
}
.q-content p.sml {
    margin-top: 22px;
    text-align: center;
    margin-bottom: 0;
    opacity: 0.5;
}

.q-content .q-steps {
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    position: absolute;
    box-sizing: border-box;
    opacity: 1;
    transition: 0.25s;
    margin: 56px auto 135px auto;
    background: var(--lghtBlue);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    min-height: auto;
    padding: 32px;
}
.questionnaire .q-content.big .q-steps {
    max-height: 900px;
    overflow: auto;
}
.q-content .q-steps.hiding {
    left: -550px;
    opacity: 0;
}
.q-content .q-steps.appearing {
    left: 550px;
    opacity: 0;
}

.q-content.big .q-steps h2 {
    margin-bottom: 32px;
}
.q-content.big .q-steps .main {
    margin-left: 382px;
}
.q-content.big .q-steps .main .sxn {
    background: var(--bgWhite);
    box-shadow: 0 2px 5px 0 rgba(173,173,173,0.5);
    padding: 32px 36px 22px 36px;
    border-radius: 14px;
    margin-bottom: 36px;
}
.q-content.big .q-steps .main .sxn h3 {
    font-size: 22px;
}
.q-content.big .q-steps button.secondary {
    float: left;
    margin-top: 22px;
}

.q-content .q-steps .thanks-txt {
    text-align: left;
    margin: 0 22px;
}
.q-content .q-steps .thanks-txt ol {
    margin-left: 22px;
}
.q-content .q-steps .thanks-txt ol li {
    padding-left: 12px;
    margin: 16px 0;
}
.q-content .q-steps .thanks-txt p {
    margin-top: 28px;
}

.q-content .q-steps button.btn {
    text-align: center !important;
}

.questionnaire .progress-bar ul {
    position: relative;
    list-style: none;
    width: auto;
    display: inline-block;
}
.questionnaire .progress-bar ul:before {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background: var(--yellow);
    position: absolute;
    top: calc(50% - 2px);
    z-index: -1;
}
.questionnaire .progress-bar ul li {
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 50%;
    border: 4px solid var(--yellow);
    background: var(--bgWhite);
    margin: 0 14px;
    position: relative;
    box-sizing: border-box;
}
.questionnaire .progress-bar ul li:first-child {
    margin-left: 0;
}
.questionnaire .progress-bar ul li:last-child {
    margin-right: 0;
}
.questionnaire .progress-bar ul li:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    position: absolute;
    margin-top: -10px;
    margin-left: -10px;
    transition: 0.5s;
}
.questionnaire .progress-bar ul li.completed:after {
    background: var(--blue);
}
.questionnaire .progress-bar ul li.active:after {
    background: var(--yellow);
}

.summary-ans {
    text-align: left;
    margin-bottom: 42px;
}
.summary-ans h6 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
}
.questionnaire .q-content.big button.btn.primary.right {
    margin-top: 22px;
}

/* Tables */
.main-content table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 22px;
}
.main-content table th {
    font-weight: 500;
    padding: 0 22px 28px 22px;
    text-align: left;
    font-size: 18px;
}
.main-content table td {
    padding: 12px 22px;
}
.main-content table tbody tr:nth-child(odd) td {
    background: var(--lghtBlue);
}
.main-content table .btn {
    border-radius: 0;
    font-size: 14px;
    margin-right: 6px;
    padding: 12px 25px;
    border: 2px solid var(--darkBlue);
    min-width: 120px;
}
.main-content table .btn:hover {
    background: var(--bgWhite);
    color: var(--darkBlue);
}
.main-content table .btn.secondary {
    background: var(--bgWhite);
    color: var(--darkBlue);
}
.main-content table .btn.secondary:hover {
    background: var(--lghtBlue);
}

.main-content table tr td span {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    background: var(--yellow);
    border-radius: 32px;
    padding: 4px 15px;
    color: var(--txtWhite);
    text-align: center;
}
.main-content table tr td span.assigned {
    background: var(--grn);
}
.main-content table tr td span.in-progress {
    background: var(--red);
}
.empty {
    text-align: center;
    opacity: 0.5;
    font-style: italic;
}

.filter {
    margin-bottom: 42px;
    background: var(--lghtBlue);
    padding: 32px;
    box-sizing: border-box;
    border: 1px solid var(--border);
    color: var(--darkBlue);
}
.filter h2 {
    margin-bottom: 32px;
    font-size: 26px;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 500;
    padding-bottom: 0;
}
.filter h2:after {
    display: none;
}

/* Admin content */
.admin-content {
    margin: 80px auto;
    padding: 0 22px;
    color: var(--darkBlue);
}
.admin-content h1 {
    font-size: 32px;
    margin-bottom: 42px;
    font-family: "Fira Sans", sans-serif;
    font-weight: 500;
}
.admin-content h1:after {
    display: none;
    padding-bottom: 0;
}

.admin-content h4 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 22px;
    border-bottom: 1px dotted var(--border);
}

.admin-content .admin-form {
    padding: 32px;
    background: var(--lghtBlue);
    margin-top: 62px;
}

/* Forms */
.form-scrn form {
    width: 75%;
}
.form-scrn form h3 {
    margin-top: 48px;
}
.permissions {
    background: var(--lghtBlue);
    padding: 32px;
    margin-bottom: 32px;
}
.permissions h6 {
    font-size: 18px;
    margin-bottom: 18px;
}
.input {
    margin-bottom: 32px;
}
.input label {
    font-size: 16px;
    margin-bottom: 12px;
    display: block;
    font-weight: 500;
    text-align: left;
}
.q-steps .input label {
    line-height: 1.5em;
}
.input input[type=text], .input input[type=number], .input input[type=email], .input input[type=password], select, .input textarea {
    display: block;
    width: 100%;
    border: none;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
    background: var(--bgWhite);
    padding: 22px 19px;
    box-sizing:border-box;
    border: 1px solid var(--border);
}
.input.checkbox input[type=checkbox] {
    float: left;
}
.input.checkbox label {
    margin-left: 32px;
}
.permissions .input.checkbox {
    margin-bottom: 15px;
}
.permissions .input.checkbox input {
    margin-top: 2px;
}
.input textarea {
    height: 200px;
    resize: none;
}
.input textarea#question {
    height: 420px;
    resize: none;
}
.input input[type=text]:focus,
.input input[type=email]:focus,
.input input[type=password]:focus,
select:focus,
.input textarea:focus {
    box-shadow: 0 0 5px var(--bgBlack);
    outline: none;
}
.input .val-msg {
  color: var(--red);
  text-align: left;
  margin-top: 5px;
  font-size: 14px;
}
.variable-inputs {
    margin-bottom: 32px;
    background: var(--lghtBlue);
    padding: 32px;
}
.flash-msg {
  background: var(--red);
  color: var(--txtWhiteBrill);
  padding: 8px 12px;
  margin-bottom: 18px;
  border-radius: 12px;
}
.flash-msg.grn, .flash-msg.green {
    background: var(--grn);
    color: var(--txtBlack);
}

button.add-btn, button.remove-btn {
    border: none;
    background: none;
    color: var(--darkBlue);
    padding-left: 24px;
    font-weight: 500;
    font-family: "Fira Sans", sans-serif;
    opacity: 0.6;
    cursor: pointer;
    transition: 0.3s;
}
button.add-btn:hover,
button.remove-btn:hover {
    opacity: 0.4;
}
button.add-btn {
    background: url('../img/ch/add.svg') left center / 16px auto no-repeat;
}
button.remove-btn {
    background: url('../img/ch/remove.svg') left center / 16px auto no-repeat;
}

/* Flash messages */
.flash-msg {
    background: var(--red);
    color: var(--txtWhite);
    padding: 15px 32px;
    margin-bottom: 32px;
    border-radius: 12px;
}
.flash-msg.grn {
    background: var(--grn);
}

/* Lightboxes */
.cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(52,78,132,0.75);
    z-index: 99;
}
.cover .close-btn {
    position: absolute;
    top: calc(20vh - 42px);
    left: calc(50% + 300px);
    width: 32px;
    height: 32px;
    cursor: pointer;
    background: url('../img/ch/close.svg') center center / 32px auto no-repeat;
    border: none;
    cursor: pointer;
    font-size: 0;
}
.cover .lb {
    background: var(--bgWhite);
    border-radius: 12px;
    width: 600px;
    position: absolute;
    top: 20vh;
    left: 50%;
    margin-left: -332px;
    padding: 24px 32px;
    z-index: 1;
}
.cover .lb h3 {
    font-size: 22px;
}
.highlight {
    background: var(--lghtBlue);
    padding: 22px 32px;
    text-align: center;
}
.highlight p {
    overflow-wrap: break-word;
}
.highlight p:last-child {
    margin-bottom: 0;
}

/* Password strength meter */
#pwForm { position: relative; }
.strength-meter {
    position: absolute;
    top: -280px;
    right: 0px;
    width: 420px;
    background: #fff;
    padding: 18px 25px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    border-radius: 12px;
    font-size: 14px;
    z-index: 1;
    display: none;
    text-align: left;
}
.strength-meter.display {
    display: block;
}
.strength-meter p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0
}
.strength-meter ul {
    list-style: none;
}
.strength-meter ul li {
    padding-left: 28px;
    line-height: 16px;
    margin: 14px 0;
    background: url('./../img/cross.svg') center left / 16px auto no-repeat;
}
.strength-meter ul li.ticked {
    background: url('./../img/tick.svg') center left / 16px auto no-repeat;
}

/* Preloader */
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
  position: relative;
  border: 3px solid;
  border-color: #eee #eee transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: 10vh auto;
  background: none !important;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent var(--yellow) var(--yellow);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 32px;
  height: 32px;
  border-color: var(--blue) var(--blue) transparent transparent;
  animation: rotation 1.5s linear infinite;
}
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}


/* Responsive styling */
@media only screen and (max-width: 1520px) {
    .landing .head-banner .bg-img {
        left: 50%;
        right: auto;
    }
    header .main-nav ul { margin: 38px 0; font-size: 18px; }
}

@media only screen and (max-width: 1352px) {
    header .content { overflow: hidden; }
    header .width-6.logo.full-admin {
        display: block;
        float: none;
        margin: 0 auto 32px auto;
    }
    header .width-6.full-admin.main-nav {
        display: block;
        float: none;
        width: 95%;
        clear: left;
        text-align: center;
        box-sizing: border-box;
    }
    header .width-6.full-admin.main-nav ul { text-align: center; margin: 0; width: 100%; }

    .main-content .width-9.centred { width: 95%; margin: 0 auto; }

}

@media only screen and (max-width: 1270px) {

    .questionnaire .q-side.rght { right: -150px; }
    .questionnaire .side-img.lft { left: -150px; }
    .questionnaire .q-side.lft { left: -150px; }
    .questionnaire .side-img.rght { right: -150px; }

    .main-content table .btn { margin-bottom: 2px; }

}

@media only screen and (max-width: 1140px) {
    .landing .head-banner { padding: 60px 0;}
    h1 { font-size: 48px; }
    h2 { font-size: 34px; }

    .main-content .width-9 { width: 95%; margin: 0 auto; }
    .main-content .width-3.filter { width: 95%; margin: 32px auto 0 auto; }
}

@media only screen and (max-width: 1060px) {
    header .logo img { max-width: 250px; }
    header .logo img.cb-logo { max-width: 90px; margin-top: 68px; }

    .landing .expectations .content .img-rght { margin: 0 1.25%; width: calc(95% - 300px); }
    .landing .expectations .third.blue h5 { font-size: 18px; }

    .questionnaire .q-side.rght { right: -280px; }
    .questionnaire .side-img.lft { left: -230px; }
    .questionnaire .q-side.lft { left: -280px; }
    .questionnaire .side-img.rght { right: -215px; }

    header .width-6.logo.full-admin { width: 384px; }

    .main-content table .btn { min-width: 20px; }

}

@media only screen and (max-width: 960px) {

    .q-content.big .q-steps .sidebar { display: none; }
    .q-content.big .q-steps .main { margin-left: 0; }

    header.setup p {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .landing .how-it-works p { font-size: 18px; }

    .questionnaire .side-img.rght { right: -185px; top: 120px; }
    .questionnaire .side-img.rght img { display: none; }

    .mid-hide { display: none !important; }

}

@media only screen and (max-width: 920px) {
    .landing .head-banner { padding: 60px 0;}
    .landing .head-banner .bg-img {
        left: 70%;
        right: auto;
    }
    .landing .head-banner .width-5 { width: 65%; }
    h1 { font-size: 40px; }
}

@media only screen and (max-width: 900px) {
    .landing .expectations .content {
        width: calc(100% - 240px);
        margin: 0 auto 0 2.5%;
    }
    .landing .expectations .content .img-rght {
        width: 95%;
    }
    .landing .expectations .third.blue {
        width: 100%;
        height: auto;
        min-height: 0;
        margin-bottom: 32px;
    }
    .landing .expectations .third.blue img { max-width: 100px; }
    .landing .how-it-works .right-girl { 
        width: 320px; 
        height: 320px; 
        top: -250px;
    }

    .questionnaire .side-img.lft { left: -230px; }
    .questionnaire .side-img.lft img { display: none; }

}

@media only screen and (max-width: 800px) {

    header .width-6 { width: 47.5%; margin: 0 1.25%; }
    header .width-6.logo.full-admin { width: 275px; margin-bottom: 12px; }
    header .logo img.cb-logo { display: none; }

    .landing .how-it-works .third { width: 100%; text-align: left; margin-bottom: 32px; }
    .landing .how-it-works .third img { float: left; width: 120px; height: 120px; margin-right: 24px; }
    .landing .how-it-works h5 { margin-top: 12px; margin-bottom: 12px; font-size: 28px; }

    .questionnaire .q-side.rght { right: -380px; }
    .questionnaire .q-side.lft { left: -380px; }

    .main-content table .btn { padding: 8px 12px; min-width: 20px; }

    .sml-hide { display: none !important; }

    .cover .lb { width: 90%; box-sizing: border-box; margin-left: 0; left: 5%; }
    .cover .close-btn { left: auto; right: 6%; }

}

@media only screen and (max-width: 720px) {
    .landing .head-banner .bg-img { display: none; }
    .landing .head-banner { padding: 42px 0; }
    .landing .head-banner .width-5 { width: 90%; margin: 0 auto; float: none; }
    h1:after { max-width: 100%;}

    .questionnaire .side-img.lft { top: 120px; }

    header .main-nav ul li a { display: block; margin-top: 18px; }

}

@media only screen and (max-width: 600px) {


    .landing .expectations {
        padding: 48px 0 150px 0;
    }
    .landing .expectations .content {
        width: 95%;
        box-sizing: border-box;
        padding: 0 12px;
    }
    .landing .how-it-works .right-girl {
        width: 250px;
        height: 250px;
        top: -170px;
    }

    button.btn, a.btn { font-size: 18px; }

    .questionnaire { margin-top: 40px; }
    .questionnaire h1 { font-size: 32px; margin-bottom: 28px; }
    .questionnaire div.intro p { font-size: 18px; }
    .questionnaire .side-img.rght { top: 180px; }

    .questionnaire button.btn, .questionnaire a.btn { font-size: 16px; padding: 12px 25px; min-width: 120px; }
    .input textarea#question { height: 320px; }

}

@media only screen and (max-width: 520px) {
    header .main-nav ul { margin: 12px 0; }
}

@media only screen and (max-width: 420px) {
    .landing .how-it-works .third { text-align: center; }
    .landing .how-it-works .third img { float: none; margin: 18px auto 8px auto; display: block; }

    .landing .how-it-works { padding: 64px 0 80px 0; }

    .questionnaire .progress-bar ul li { margin: 0 6px; }
    .questionnaire button.btn, .questionnaire a.btn { padding: 12px 15px; min-width: 80px; }

    header .main-nav ul { margin: 0; }
}